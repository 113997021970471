<template>
    <OTreeList :dataObject="props.dataObject" :field="props.displayField" autoCloseContextMenu>
        <template #aboveList>
            <div class="mt-1 mb-2">
                <span class="me-3">
                    <input class="form-check-input" type="checkbox" id="_restrictcontext" v-model="_restrictToContext" @change="triggerRestrictToContext();">
                    <label class="form-check-label ms-1" for="_restrictcontext">{{$t('Restrict To User Context')}}</label>
                </span>
                <span>
                    <slot name="action"></slot>
                </span>
            </div>

        </template>
        <template #default="{row}">
            <a class="text-truncate text-dark" role="button">{{ row[props.displayField] }}</a>
        </template>
        <template #icons="{row: folder}">
            <i class="me-auto"></i>
            <i v-if="!props.working" class="bi bi-check text-primary me-2" style="font-size:28px;" role="button" @click="folderClicked(folder)" :title="$t('Choose folder')"></i>
            <i v-else-if="folder.index == workingIdx" class="spinner-border spinner-border-sm me-2 d-flex"></i>
        </template>
    </OTreeList>

</template>

<script setup>
    import { ref, watch } from 'vue';
    import { context } from 'o365-modules';
    import 'o365-nodedata';
    import { OTreeList } from 'o365-nodedata';

    const props = defineProps({
        dataObject: Object,
        onFolderSelected: Function,
        displayField: String,
        working: { type: Boolean, required: false, default: false },
        fieldForTreeify: { type: String, required: false, default:'IdPath' },
    })

    const emit = defineEmits(['folderClick'])

    const workingIdx = ref(null);

    const _restrictToContext = ref(false);
    let vWhereClause = `OrgUnitAccessIdPath LIKE '`+ context.idPath + `%'`;

    props.dataObject.nodeData.addConfiguration({
        idPathField: 'IdPath',
        type: 'hierarchy'
    });
    props.dataObject.nodeData.disableLocalStore = true;
    props.dataObject.nodeData.enable();

    loadFoldersTree();

    context.onChanged(() => {
        vWhereClause = `OrgUnitAccessIdPath LIKE '`+ context.idPath + `%'`;
        loadFoldersTree();
    });

    async function loadFoldersTree(){
        props.dataObject.recordSource.whereClause = _restrictToContext.value == true ? vWhereClause : "";
        props.dataObject.nodeData.addConfiguration({
            idPathField: props.fieldForTreeify,
            type: 'hierarchy'
        })
        props.dataObject.nodeData.enable();
        props.dataObject.load();
    }

    function triggerRestrictToContext(){
        props.dataObject.recordSource.whereClause = _restrictToContext.value == true ? vWhereClause : "";
        props.dataObject.load();
    }

    watch(() => props.working, ()=>{
        if (!props.working){
            workingIdx.value = false;
        }
    });

    const folderClicked = (folder)=>{
        workingIdx.value = folder.index;
        emit('folderClick', folder);
    }

</script>